import { authedEndpoint } from "~/lib/api/builder";
import { z } from "zod";
import { router } from "@smakecloud/api-client-helper";

const productionProductionMethodSchema = z.object({
    id: z.number(),
    production_id: z.number(),
    production_method_handle: z.string(),
    has_presorting: z.boolean(),
    is_using_presorting: z.boolean(),
    production_method: z.object({
        id: z.number(),
        display_name: z.string().nullable(),
        description: z.string().nullable(),
        is_available: z.boolean(),
        is_digitizable: z.number(),
        production_areas_count: z.number().nullable(),
        information_digitalizer: z.string().nullable(),
        information_producer: z.string().nullable(),
        production_type: z.object({
            id: z.number(),
            name: z.string().nullable(),
        }),
    }),
});

const list = authedEndpoint.query(
    (searchParams) => ({
        url: "production-methods",
        searchParams,
    }),
    {
        input: z
            .object({
                cursor: z.string(),
                filter: z
                    .object({
                        q: z.string(),
                    })
                    .partial(),
                per_page: z.number(),
            })
            .partial()
            .optional(),
        output: z.object({
            data: z.array(productionProductionMethodSchema),
            meta: z.object({
                path: z.string(),
                per_page: z.number(),
                next_cursor: z.string().nullable(),
                prev_cursor: z.string().nullable(),
            }),
            links: z.object({
                first: z.string().nullable(),
                last: z.string().nullable(),
                prev: z.string().nullable(),
                next: z.string().nullable(),
            }),
        }),
    },
);

const get = authedEndpoint.query(
    (id) => ({
        url: `production-methods/${id}`,
    }),
    {
        input: z.number(),
        output: productionProductionMethodSchema,
    },
);

const activatePresorting = authedEndpoint.mutation(
    (id) => ({
        url: `production-methods/${id}/presort/activate`,
        method: "POST",
    }),
    {
        input: z.number(),
        output: productionProductionMethodSchema,
    },
);

const deactivatePresorting = authedEndpoint.mutation(
    (id) => ({
        url: `production-methods/${id}/presort/deactivate`,
        method: "POST",
    }),
    {
        input: z.number(),
        output: productionProductionMethodSchema,
    },
);

import { sortingRacks } from "./sortingRacks";

export const productionMethods = router({
    list,
    get,
    activatePresorting,
    deactivatePresorting,

    // Sub-Endpoints
    sortingRacks,
});
