import { publicEndpoint } from "~/lib/api/builder";
import { z } from "zod";
import { router } from "@smakecloud/api-client-helper";

const login = publicEndpoint.mutation(
    (json) => ({
        url: "auth/login",
        method: "POST",
        json,
    }),
    {
        input: z.object({
            email: z.string(),
            password: z.string(),
            slug: z.string(),
        }),
        output: z.object({
            access_token: z.string(),
            refresh_token: z.string(),
            refresh_token_ttl: z.string(),
        }),
    },
);

const fetchRefreshTokenRetryLimit = 3;

const refresh = publicEndpoint.mutation(
    (refresh_token) => ({
        url: "auth/refresh",
        method: "POST",
        json: { refresh_token },
        retry: fetchRefreshTokenRetryLimit,
    }),
    {
        input: z.string(),
        output: z.object({
            access_token: z.string(),
            refresh_token: z.string(),
            refresh_token_ttl: z.string(),
        }),
    },
);

export const auth = router({ login, refresh });
