import type { UseStorageOptions } from "@vueuse/core";

export default <T>(
    name: string,
    defaultValue: T,
    options?: UseStorageOptions<T>,
) => {
    const slug = useSlug();

    return useLocalStorage(
        `${slug.value ?? "*"}.${name}`,
        defaultValue,
        options,
    );
};
