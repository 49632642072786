import { authedEndpoint } from "~/lib/api/builder";
import { z } from "zod";
import { router } from "@smakecloud/api-client-helper";

const stockItemSchema = z.object({
    id: z.number(),
    count_on_hand: z.number(),
    product: z.object({
        name: z.string().nullable(),
    }),
    variant: z.object({
        id: z.number(),
        code: z.string().nullable(),
        option_values: z.object({
            color: z.string().nullable(),
            size: z.string().nullable(),
        }),
    }),
    media: z.object({
        sm: z.string().nullable(),
    }),
});

const list = authedEndpoint.query(
    (searchParams) => ({
        url: "stock-items",
        searchParams,
    }),
    {
        input: z
            .object({
                cursor: z.string(),
                filter: z
                    .object({
                        q: z.string(),
                    })
                    .partial(),
                per_page: z.number(),
            })
            .partial()
            .optional(),
        output: z.object({
            data: z.array(stockItemSchema),
            meta: z.object({
                path: z.string(),
                per_page: z.number(),
                next_cursor: z.string().nullable(),
                prev_cursor: z.string().nullable(),
            }),
            links: z.object({
                first: z.string().nullable(),
                last: z.string().nullable(),
                prev: z.string().nullable(),
                next: z.string().nullable(),
            }),
        }),
    },
);

const update = authedEndpoint.mutation(
    ({ id, count_on_hand, set_stock_item_type }) => ({
        url: `stock-items/${id}`,
        method: "POST",
        json: {
            count_on_hand,
            set_stock_item_type,
        },
    }),
    {
        input: z.object({
            id: z.number(),
            count_on_hand: z.number(),
            set_stock_item_type: z.string(),
        }),
        output: stockItemSchema,
    },
);

const exportList = authedEndpoint.mutation(
    (request) => ({
        url: "stock-items/export",
        method: "POST",
        json: request,
    }),
    {
        input: z.object({
            file_format: z.string(),
            separator: z.string(),
        }),
    },
);

const importList = authedEndpoint.mutation(
    (file) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
            url: "stock-items/import",
            method: "POST",
            body: formData,
            headers: {
                /*
                 * Setting Content-Type to undefined fixes the issue with "missing boundary in multiform/form-data"
                 * See: https://stackoverflow.com/a/39281156
                 */
                "Content-Type": undefined,
            },
        };
    },
    {
        input: z.instanceof(Blob),
    },
);

export const stockItems = router({
    list,
    update,
    exportList,
    importList,
});
