import { authedEndpoint } from "~/lib/api/builder";
import { z } from "zod";
import { router } from "@smakecloud/api-client-helper";

const verify = authedEndpoint.query(
    () => ({
        url: "production/verify",
    }),
    {
        output: z.object({
            id: z.number(),
            tenant_id: z.number(),
            name: z.string(),
            channel: z.string(),
            timezone: z.string(),
            owner_id: z.number().nullable(),
            default_locale_id: z.number(),
            default_locale_code: z.string(),
            handle: z.string(),
            locales: z.array(
                z.object({
                    id: z.number(),
                    code: z.string(),
                    name: z.string(),
                }),
            ),
            created_at: z.string().datetime({ offset: true }),
            updated_at: z.string().datetime({ offset: true }),
            deleted_at: z.string().datetime({ offset: true }).nullable(),
        }),
    },
);

export const production = router({
    verify,
});
