import { storeToRefs } from "pinia";
import {
    createEndpointBuilder,
    createEtagMiddleware,
} from "@smakecloud/api-client-helper";
import { routeMiddleware } from "./middlewares/routeMiddleware";
import { flare } from "@flareapp/js";

export const publicEndpoint = createEndpointBuilder(
    {
        onValidationError: (error) => {
            void flare.report(error);
        },
    },
    [createEtagMiddleware("production-frontend-api"), routeMiddleware],
);

export const authedEndpoint = publicEndpoint.use(() => {
    const { token, payload, isLoggedIn } = storeToRefs(useAuthStore());

    const userId = computed(() => payload.value?.user_id);

    return {
        beforeRequest: (options) => ({
            ...options,
            headers: {
                ...options.headers,
                Authorization: `Bearer ${token.value}`,
            },
        }),
        enabled: isLoggedIn,
        key: [userId],
    };
});
