import { authedEndpoint } from "~/lib/api/builder";
import { z } from "zod";
import { router } from "@smakecloud/api-client-helper";

const typeSchema = z.object({
    id: z.number(),
    name: z.string(),
});

const modelSchema = z.object({
    id: z.number(),
    machine_type_id: z.number(),
    manufacturer_id: z.number(),
    name: z.string(),
    protocol: z.string().nullable(),
    profile_xml: z.string().nullable(),
    positions: z.number().nullable(),
    production_type_id: z.number().nullable(),
    profile_jdf: z.string().nullable(),
    type: typeSchema,
});

const machineSchema = z.object({
    id: z.number(),
    name: z.string().nullable(),
    description: z.string().nullable(),
    ip_address: z.string().nullable(),
    external_identifier: z.string().nullable(),
    model: modelSchema,
});

const list = authedEndpoint.query(
    () => ({
        url: `machinery/machines`,
    }),
    {
        output: z.object({
            data: z.array(machineSchema),
            meta: z.object({
                path: z.string(),
                per_page: z.number(),
                next_cursor: z.string().nullable(),
                prev_cursor: z.string().nullable(),
            }),
            links: z.object({
                first: z.string().nullable(),
                last: z.string().nullable(),
                prev: z.string().nullable(),
                next: z.string().nullable(),
            }),
        }),
    },
);

const print = authedEndpoint.mutation(
    ({ id, dpi, rack_id, bin_ids }) => ({
        url: `machinery/machines/${id}/print-presort-label`,
        json: { dpi, rack_id, bin_ids },
        method: "post",
    }),
    {
        input: z.object({
            id: z.number(),
            dpi: z.number(),
            rack_id: z.number().optional(),
            bin_ids: z.array(z.number()).optional(),
        }),
        output: z.object({
            message: z.string(),
        }),
    },
);

export const machinery = router({
    list,
    print,
});
