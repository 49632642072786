import type { MiddlewareResult } from "@smakecloud/api-client-helper";

export function routeMiddleware(): MiddlewareResult {
    const config = useRuntimeConfig();
    const slug = useSlug();

    const { locale } = storeToRefs(useLocalSettingsStore());

    const hasSlug = computed(() => slug.value !== undefined);
    const acceptLanguage = computed(() => locale.value ?? config.public.locale);

    return {
        beforeRequest: (options) => ({
            ...options,
            prefixUrl: `${config.public.productionApiBaseUrl}/${slug.value}`,
            headers: {
                ...options.headers,
                "Accept-Language": acceptLanguage.value,
            },
        }),
        key: [slug, acceptLanguage],
        enabled: hasSlug,
    };
}
