import type { JWTPayload as BasicJWTPayload } from "jose";
import { decodeJwt } from "jose";
import { api } from "~/lib/api";
import { md5 } from "~/lib/hashing";

export type JWTPayload = BasicJWTPayload & {
    channel: string;
    default_locale_code: string;
    handle: string;
    is_admin: boolean;
    is_owner: boolean;
    login_url: string;
    permissions: string[];
    production_id: number;
    production_name: string;
    prv: string;
    staff_email: string;
    staff_id: number;
    user_id: number;
};

const oneMinute = 60 * 1000;

export const useAuthStore = defineStore("authStore", () => {
    const slug = useSlug();

    const { setLocale } = useLocalSettingsStore();
    const { locale_overridden } = useLocalSettingsStore();

    const timer = ref<ReturnType<typeof setTimeout>>();
    const token = useSlugLocalStorage<string | undefined>(
        "production-administration-tokenStore-token",
        undefined,
    );

    const refreshToken = useSlugLocalStorage<string | undefined>(
        "production-administration-tokenStore-refreshToken",
        undefined,
    );

    const refreshTokenTtl = useSlugLocalStorage<string | undefined>(
        "production-administration-tokenStore-refreshTokenTtl",
        undefined,
    );

    const tokenLogoutHash = useSlugLocalStorage<string | undefined>(
        "production-administration-tokenStore-tokenLogoutHash",
        undefined,
    );

    const now = useNow({ interval: oneMinute });

    const isRefreshTokenExpired = computed<boolean>(() => {
        if (refreshTokenTtl.value === undefined) {
            return true;
        }

        return (
            new Date(refreshTokenTtl.value).getTime() - now.value.getTime() < 0
        );
    });

    const payload = computed(() => {
        if (!token.value) return undefined;

        try {
            return decodeJwt<JWTPayload>(token.value);
        } catch {
            return undefined;
        }
    });

    const isTokenExpired = computed<boolean>(() => {
        if (payload.value?.exp === undefined) {
            return true;
        }

        return payload.value.exp * 1000 - now.value.getTime() - oneMinute < 0;
    });

    const isLoggedIn = computed<boolean>(
        () =>
            token.value !== undefined &&
            (!isTokenExpired.value || !isRefreshTokenExpired.value),
    );

    const isLoggedOut = computed<boolean>(
        () =>
            tokenLogoutHash.value !== undefined &&
            md5(token.value ?? "") === tokenLogoutHash.value,
    );

    const loginEndpoint = api.auth.login.use();

    const { mutateAsync: loginCall } = useMutation({
        ...loginEndpoint,
        onSuccess: (data) => {
            setToken({
                token: data.access_token,
                refreshToken: data.refresh_token,
                refreshTokenTtl: data.refresh_token_ttl,
            });

            if (!locale_overridden) {
                setLocale(payload.value?.default_locale_code ?? "en");
            }

            tokenLogoutHash.value = undefined;
        },
        onError: (error: Error) => {
            return error;
        },
    });

    const refreshEndpoint = api.auth.refresh.use();

    const { mutateAsync: refreshCall } = useMutation({
        ...refreshEndpoint,
        onSuccess: (data) => {
            setToken({
                token: data.access_token,
                refreshToken: data.refresh_token,
                refreshTokenTtl: data.refresh_token_ttl,
            });
        },
    });

    async function login(email: string, password: string, slug: string) {
        await loginCall({
            email,
            password,
            slug,
        });

        reloadNuxtApp();
    }

    function logout() {
        if (!isLoggedIn.value && !slug) {
            return;
        }

        if (token.value) {
            tokenLogoutHash.value = md5(token.value);
        }

        setToken();

        reloadNuxtApp();
    }

    async function refresh() {
        if (refreshToken.value) {
            if (isLoggedOut) {
                return;
            }

            await refreshCall(refreshToken.value);
        }
    }

    function startRefreshTokenTimer() {
        if (timer.value) clearTimeout(timer.value);

        if (!payload.value?.exp) {
            throw new Error('"exp" not set in JWT!');
        }

        const timeout =
            payload.value?.exp * 1000 - now.value.getTime() - oneMinute;
        timer.value = setTimeout(() => {
            refresh().catch((error) => console.log(error));
        }, timeout);
    }

    function setToken(tokenData?: {
        token?: string;
        refreshToken?: string;
        refreshTokenTtl?: string;
    }) {
        refreshToken.value = tokenData?.refreshToken;
        refreshTokenTtl.value = tokenData?.refreshTokenTtl;
        token.value = tokenData?.token;

        if (token.value && payload.value?.exp) startRefreshTokenTimer();
        else if (timer.value) clearTimeout(timer.value);
    }

    // Sync changes to the token or refreshToken in real-time
    watch([token, refreshToken, refreshTokenTtl], ([newToken]) => {
        if (!newToken) {
            return;
        }
    });

    watchEffect(() => {
        if (isTokenExpired.value && !isRefreshTokenExpired.value) {
            if (isLoggedOut) {
                void logout();
            } else {
                void refresh();
            }
        }
    });

    setToken({
        token: token.value,
        refreshToken: refreshToken.value,
    });

    return {
        token: readonly(token),
        refreshToken: readonly(refreshToken),
        payload: readonly(payload),
        isLoggedIn,
        isLoggedOut,
        isTokenExpired,
        isRefreshTokenExpired,
        login,
        logout,
        refresh,
        setToken,
    };
});
