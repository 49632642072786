import { authedEndpoint } from "~/lib/api/builder";
import { z } from "zod";
import { router } from "@smakecloud/api-client-helper";

const binElementSchema = z.object({
    id: z.number(),
    id_tag_id: z.number(),
    production_order_item_unit_customization_id: z.number(),
    type: z.string().nullable(),
    added_to_bin_at: z.string().datetime({ offset: true }).nullable(),
    created_at: z.string().datetime({ offset: true }),
    updated_at: z.string().datetime({ offset: true }),
});

const sortingBinSchema = z.object({
    id: z.number(),
    rack_bin_identifier: z.string(),
    id_tag_id: z.number().nullable(),
    sorting_rack_id: z.number(),
    status: z.enum(["full", "empty", "partially_filled"]).nullable(),
    sorting_bin_elements: z.array(binElementSchema),
    created_at: z.string().datetime({ offset: true }),
    updated_at: z.string().datetime({ offset: true }),
});

const customizationSchema = z.object({
    id: z.number(),
    view_type_name: z.string(),
    length_unit: z.string(),
    height_mm: z.number().nullable(),
    width_mm: z.number().nullable(),
    height_px: z.number().nullable(),
    width_px: z.number().nullable(),
    localized_width: z.number().or(z.string()),
    localized_height: z.number().or(z.string()),
    identifier: z.string().nullable(),
    preview_url: z.string().nullable(),
    created_at: z.string().datetime({ offset: true }),
    updated_at: z.string().datetime({ offset: true }),
});

const compositeImageSchema = z.object({
    id: z.number(),
    view_type_name: z.string(),
    media: z.array(
        z.object({
            id: z.number(),
            sm: z.string().nullable(),
            md: z.string().nullable(),
            lg: z.string().nullable(),
            created_at: z.string().datetime({ offset: true }),
            updated_at: z.string().datetime({ offset: true }),
        }),
    ),
    created_at: z.string().datetime({ offset: true }),
    updated_at: z.string().datetime({ offset: true }),
});

const variantSchema = z.object({
    id: z.number(),
    name: z.string(),
    color: z.string().nullable(),
    size: z.string().nullable(),
    composite_images: z.array(compositeImageSchema),
    created_at: z.string().datetime({ offset: true }),
    updated_at: z.string().datetime({ offset: true }),
});

const idTagSchema = z.object({
    id: z.number(),
    variant: variantSchema,
    created_at: z.string().datetime({ offset: true }),
    updated_at: z.string().datetime({ offset: true }),
});

const productionOrderItemUnitCustomizationSchema = z.object({
    id: z.number(),
    customization: customizationSchema,
    created_at: z.string().datetime({ offset: true }),
    updated_at: z.string().datetime({ offset: true }),
});

const expandedSortingBinElementSchema = binElementSchema
    .omit({
        production_order_item_unit_customization_id: true,
        id_tag_id: true,
    })
    .extend({
        production_order_item_unit_customization:
            productionOrderItemUnitCustomizationSchema.nullable(),
        id_tag: idTagSchema.nullable(),
    });

const expandedSortingBinSchema = sortingBinSchema.extend({
    sorting_bin_elements: z.array(expandedSortingBinElementSchema),
});

const getBin = authedEndpoint.query(
    ({ production_method_id, sorting_rack_id, sorting_rack_bin_id }) => ({
        url: `production-methods/${production_method_id}/sorting-racks/${sorting_rack_id}/bins/${sorting_rack_bin_id}`,
    }),
    {
        input: z.object({
            production_method_id: z.number(),
            sorting_rack_id: z.number(),
            sorting_rack_bin_id: z.number(),
        }),
        output: expandedSortingBinSchema,
    },
);

const addBins = authedEndpoint.mutation(
    ({ production_method_id, sorting_rack_id, number_of_bins }) => ({
        url: `production-methods/${production_method_id}/sorting-racks/${sorting_rack_id}/bins`,
        method: "POST",
        json: {
            number_of_bins,
        },
    }),
    {
        input: z.object({
            production_method_id: z.number(),
            sorting_rack_id: z.number(),
            number_of_bins: z.number(),
        }),
        output: z.array(sortingBinSchema),
    },
);

const deleteBins = authedEndpoint.mutation(
    ({ production_method_id, sorting_rack_id, bin_ids }) => ({
        url: `production-methods/${production_method_id}/sorting-racks/${sorting_rack_id}/bins`,
        method: "DELETE",
        json: { bin_ids },
    }),
    {
        input: z.object({
            production_method_id: z.number(),
            sorting_rack_id: z.number(),
            bin_ids: z.array(z.number()),
        }),
    },
);

const clearBins = authedEndpoint.mutation(
    ({ production_method_id, sorting_rack_id, bin_ids }) => ({
        url: `production-methods/${production_method_id}/sorting-racks/${sorting_rack_id}/bins/clear`,
        method: "PUT",
        json: { bin_ids },
    }),
    {
        input: z.object({
            production_method_id: z.number(),
            sorting_rack_id: z.number(),
            bin_ids: z.array(z.number()),
        }),
        output: sortingBinSchema,
    },
);

export const sortingRackBins = router({
    get: getBin,
    add: addBins,
    delete: deleteBins,
    clear: clearBins,
});
