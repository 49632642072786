/**
 * Authorize user
 */
export default defineNuxtRouteMiddleware((to) => {
    const { isAuthorised } = useAuthorisationChecker();
    const isAuthorisedForPage = isAuthorised(to.meta);

    if (!isAuthorisedForPage) {
        return showError({
            statusCode: 404,
        });
    }

    return;
});
