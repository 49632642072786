import type { RouteLocationNormalized } from "vue-router";

/**
 * Ensures user is authenticated
 */
export default defineNuxtRouteMiddleware(async (to) => {
    const slug = useSlug();

    // Case: Slug is not defined or invalid
    if (!slug.value) {
        return showError({
            statusCode: 404,
        });
    }

    const { isLoggedIn } = storeToRefs(useAuthStore());

    if (!isLoggedIn.value) {
        if (isGuestPage(to)) return;

        return navigateToLogin(slug.value, to);
    }

    // Case: User came from another page
    if (hasRedirectParameter(to)) {
        return attemptNavigateToRedirect(slug.value, to);
    }

    // Case: User tries to access login or entrance page
    if (isGuestPage(to)) {
        return navigateToDashboard(slug.value);
    }

    return;
});

function navigateToLogin(slug: string, to: RouteLocationNormalized) {
    const fullPath = useRoute().fullPath;

    if (fullPath == `/${slug}/dashboard` || is404Page(to)) {
        return navigateTo({
            name: "slug-login",
            params: { slug },
        });
    }

    return navigateTo({
        name: "slug-login",
        params: { slug },
        query: { redirect: to.fullPath },
    });
}

function navigateToDashboard(slug: string) {
    return navigateTo({
        name: "slug-dashboard",
        params: { slug },
    });
}

function attemptNavigateToRedirect(slug: string, to: RouteLocationNormalized) {
    const path = decodeURIComponent((to.query.redirect as string) ?? "");

    if (!path || !path.startsWith(`/${slug}`)) {
        return navigateToDashboard(slug);
    }

    const pathElements = path.split("/").filter((p) => p);

    if (!isRedirectParameterValid(pathElements)) {
        return navigateToDashboard(slug);
    }

    const { options } = useRouter();

    const page = options.routes.find((route) => {
        const name = route.name as string;

        return name === `slug-${pathElements[1]}`;
    });

    if (!page) {
        return navigateToDashboard(slug);
    }

    return navigateTo(page);
}

function isRedirectParameterValid(elements: string[]) {
    if (elements.length <= 1) {
        return false;
    }

    if (elements.length >= 2 && elements[1].toLowerCase() == "login") {
        return false;
    }

    return true;
}

function hasRedirectParameter(to: RouteLocationNormalized): boolean {
    return to.query.redirect !== undefined && to.query.redirect !== "";
}
