// Token refreshing is by design reactive.
// But if a user was on a other website an returns to this website we want to ensure token refreshing is awaited before checking the authentication
export default defineNuxtRouteMiddleware(async () => {
    const authStore = useAuthStore();
    const { refresh } = authStore;
    const { isTokenExpired } = storeToRefs(authStore);

    if (isTokenExpired.value) {
        await refresh();
    }

    return;
});
