export default function useAuthorisationChecker() {
    const { payload } = storeToRefs(useAuthStore());

    function isAuthorised({
        dev,
        permission,
    }: {
        dev?: boolean;
        permission?: string;
    }) {
        if (!permission) return true;

        if (!payload.value) return false;

        if (payload.value.is_owner && !dev) return true;

        if (payload.value.is_admin) return true;

        if (!payload.value.permissions.includes(permission)) return false;

        return true;
    }

    return { isAuthorised };
}
