import { decodeJwt } from "jose";

type LocalStorageSettings = {
    locale?: string;
    lastSeen?: string;
};

export default defineNuxtRouteMiddleware(() => {
    const activeSlug = useSlug();

    const localStorageKeys = computed(() => Object.keys(localStorage));

    const slugs = computed(() => {
        const slugSet = new Set<string>();
        localStorageKeys.value.forEach((key) => {
            if (!key.includes(".")) return;
            const slug = key.split(".")[0];
            slugSet.add(slug);
        });
        return Array.from(slugSet);
    });

    const validSettings: { key: string; lastSeen: number | null }[] = [];

    slugs.value.forEach((slug) => {
        const settingsKey = `${slug}.production-administration-localSettings-settings`;
        const settings = useLocalStorage<string | undefined>(
            settingsKey,
            undefined,
        );

        if (!settings.value) {
            deleteAllEntriesForSlug(slug);
            return;
        }

        try {
            const settingsObj = parseSettings(settings.value);

            if (!settingsObj?.lastSeen) {
                deleteAllEntriesForSlug(slug);
                return;
            }

            const lastSeenTime = Number(settingsObj.lastSeen);

            if (isOlderThanOneWeek(lastSeenTime)) {
                if (slug !== activeSlug.value) {
                    deleteAllEntriesForSlug(slug);
                    return;
                }
                updateActiveSlugLastSeen();
            }

            checkAndDeleteInvalidToken(slug);

            validSettings.push({ key: settingsKey, lastSeen: lastSeenTime });
        } catch (error) {
            console.error(`Failed to parse settings for slug: ${slug}`, error);
            deleteAllEntriesForSlug(slug);
        }
    });

    validSettings.sort(
        (first, second) => (second.lastSeen ?? 0) - (first.lastSeen ?? 0),
    );

    if (validSettings.length > 10) {
        const excessSettings = validSettings.slice(10);
        excessSettings.forEach(({ key }) => {
            const slugOfKey = key.split(".")[0];
            if (slugOfKey === activeSlug.value) return;

            deleteAllEntriesForSlug(slugOfKey);
        });
    }
});

/**
 * Parses the localStorage settings value and returns the parsed object.
 */
function parseSettings(settingsValue: string): LocalStorageSettings | null {
    try {
        return JSON.parse(settingsValue) as LocalStorageSettings;
    } catch (error) {
        console.error(`Error parsing settings:`, error);
        return null;
    }
}

/**
 * Deletes only the token-related localStorage entries for the given slug.
 */
function deleteTokenEntries(slugOfKey: string): void {
    const token = useLocalStorage<string | undefined>(
        `${slugOfKey}.production-administration-tokenStore-token`,
        undefined,
    );
    const refreshToken = useLocalStorage<string | undefined>(
        `${slugOfKey}.production-administration-tokenStore-refreshToken`,
        undefined,
    );
    const refreshTokenTtl = useLocalStorage<string | undefined>(
        `${slugOfKey}.production-administration-tokenStore-refreshTokenTtl`,
        undefined,
    );

    token.value = undefined;
    refreshToken.value = undefined;
    refreshTokenTtl.value = undefined;
}

/**
 * Deletes all localStorage entries (including settings) for the given slug.
 */
function deleteAllEntriesForSlug(slugOfKey: string): void {
    deleteTokenEntries(slugOfKey);

    const settings = useLocalStorage<string | undefined>(
        `${slugOfKey}.production-administration-localSettings-settings`,
        undefined,
    );
    settings.value = undefined;
}

/**
 * Updates the lastSeen value for the active slug.
 */
function updateActiveSlugLastSeen(): void {
    const settings = useSlugLocalStorage<string | undefined>(
        "production-administration-localSettings-settings",
        undefined,
    );

    if (settings.value) {
        try {
            const settingsObj = JSON.parse(
                settings.value,
            ) as LocalStorageSettings;
            settingsObj.lastSeen = Date.now().toString();
            settings.value = JSON.stringify(settingsObj);
        } catch (error) {
            console.error(
                `Error updating lastSeen for production-administration-localSettings-settings`,
                error,
            );
        }
    }
}

/**
 * Checks if the JWT token is valid, and deletes token entries if invalid.
 */
function checkAndDeleteInvalidToken(slug: string): void {
    const token = useLocalStorage<string | undefined>(
        `${slug}.production-administration-tokenStore-token`,
        undefined,
    );
    if (token.value && !isJwtTokenValid(token.value)) {
        deleteTokenEntries(slug);
    }
}

/**
 * Helper function to check if the timestamp is older than one week.
 */
function isOlderThanOneWeek(timestampInMs: number): boolean {
    const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;
    const currentTimeInMs = Date.now();
    return currentTimeInMs - timestampInMs > oneWeekInMs;
}

/**
 * Helper function to check if the JWT token is still valid.
 */
export function isJwtTokenValid(token: string): boolean {
    if (!token) return false;

    try {
        const payload = decodeJwt(token);
        if (!payload.exp) return false;

        const currentTime = Math.floor(Date.now() / 1000);
        return payload.exp > currentTime;
    } catch (error) {
        console.error("Invalid token:", error);
        return false;
    }
}
