import { auth } from "~/lib/api/endpoints/auth";
import { production } from "~/lib/api/endpoints/production";
import { productionMethods } from "~/lib/api/endpoints/productionMethods";
import { machinery } from "~/lib/api/endpoints/machinery";
import { stockItems } from "~/lib/api/endpoints/stockItems";
import { router } from "@smakecloud/api-client-helper";

export const api = router(
    {
        auth,
        machinery,
        production,
        productionMethods,
        stockItems,
    },
    "production-frontend",
);
