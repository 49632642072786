export const useLocalSettingsStore = defineStore("localSettings", () => {
    const config = useRuntimeConfig();

    const settings = useSlugLocalStorage(
        "production-administration-localSettings-settings",
        {
            locale: config.public.locale,
            locale_overridden: false,
            lastSeen: Date.now().toString(),
        },
    );

    function setLocale(locale: string) {
        settings.value.locale = locale;
        settings.value.locale_overridden = true;
    }

    return {
        ...toRefs(settings.value),
        setLocale,
    };
});
