
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indextETsrqzz2D1iaBeA8QTr8_45MTFrmI6AYLUZ9RP2o829YMeta } from "/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/index.vue?macro=true";
import { default as login9OJGjrfLYD3KVADk5KVdoMkqSWyiiYLxL4uAMq956LUMeta } from "/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/login.vue?macro=true";
import { default as entranceZ49GjELRpFa1T4HLS6QDKvVcBAd_45y_cxf1zh5K4uXI4Meta } from "/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/entrance.vue?macro=true";
import { default as dashboardUKEG0yvW5LYVqqdpIoquzbL9eAvaq7byO3ZcjAenwUcMeta } from "/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/dashboard.vue?macro=true";
import { default as inventorylsT9CVWy8LejKEX6abHJM_45dxfS8zUuYJo05CRPOS6YMMeta } from "/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/inventory.vue?macro=true";
import { default as _91id_93PAE_45nfnG8puumAAj3QPkE2MoJ_hkPuwq_PFntQmKTzQMeta } from "/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/production-methods/[id].vue?macro=true";
import { default as indexGiWqnlY_45vXKFPXKgIwWSo50lo6Enylr0OpuzAxq2fzAMeta } from "/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/production-methods/index.vue?macro=true";
import { default as indexE9qDkZfYJ8_7jEhNNj5yIuQi99SzkaNKhN_451ENxAsgEMeta } from "/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/presorting/[productionMethodId]/index.vue?macro=true";
import { default as _91id_93NLJoIjIv5j_45NG4_45_Cy5qB3oPihhYs775Oy1X_YlK4UUMeta } from "/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/presorting/[productionMethodId]/racks/[id].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/index.vue")
  },
  {
    name: "slug-login",
    path: "/:slug()/login",
    meta: login9OJGjrfLYD3KVADk5KVdoMkqSWyiiYLxL4uAMq956LUMeta || {},
    component: () => import("/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/login.vue")
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entranceZ49GjELRpFa1T4HLS6QDKvVcBAd_45y_cxf1zh5K4uXI4Meta || {},
    component: () => import("/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/entrance.vue")
  },
  {
    name: "slug-dashboard",
    path: "/:slug()/dashboard",
    component: () => import("/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/dashboard.vue")
  },
  {
    name: "slug-inventory",
    path: "/:slug()/inventory",
    meta: inventorylsT9CVWy8LejKEX6abHJM_45dxfS8zUuYJo05CRPOS6YMMeta || {},
    component: () => import("/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/inventory.vue")
  },
  {
    name: "slug-production-methods-id",
    path: "/:slug()/production-methods/:id()",
    meta: _91id_93PAE_45nfnG8puumAAj3QPkE2MoJ_hkPuwq_PFntQmKTzQMeta || {},
    component: () => import("/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/production-methods/[id].vue")
  },
  {
    name: "slug-production-methods",
    path: "/:slug()/production-methods",
    meta: indexGiWqnlY_45vXKFPXKgIwWSo50lo6Enylr0OpuzAxq2fzAMeta || {},
    component: () => import("/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/production-methods/index.vue")
  },
  {
    name: "slug-presorting-productionMethodId",
    path: "/:slug()/presorting/:productionMethodId()",
    meta: indexE9qDkZfYJ8_7jEhNNj5yIuQi99SzkaNKhN_451ENxAsgEMeta || {},
    component: () => import("/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/presorting/[productionMethodId]/index.vue")
  },
  {
    name: "slug-presorting-productionMethodId-racks-id",
    path: "/:slug()/presorting/:productionMethodId()/racks/:id()",
    meta: _91id_93NLJoIjIv5j_45NG4_45_Cy5qB3oPihhYs775Oy1X_YlK4UUMeta || {},
    component: () => import("/home/forge/productions.smake.cloud/releases/v1.6.0/pages/[slug]/presorting/[productionMethodId]/racks/[id].vue")
  }
]