import { authedEndpoint } from "~/lib/api/builder";
import { z } from "zod";
import { router } from "@smakecloud/api-client-helper";

const binElementSchema = z.object({
    id: z.number(),
    id_tag_id: z.number().optional(),
    production_order_item_unit_customization_id: z.number().optional(),
    type: z.string().nullable(),
    added_to_bin_at: z.string().datetime({ offset: true }).nullable(),
    created_at: z.string().datetime({ offset: true }),
    updated_at: z.string().datetime({ offset: true }),
});

const sortingBinSchema = z.object({
    id: z.number(),
    rack_bin_identifier: z.string(),
    id_tag_id: z.number().nullable(),
    sorting_rack_id: z.number(),
    status: z.enum(["full", "empty", "partially_filled"]).nullable(),
    sorting_bin_elements: z.array(binElementSchema).nullable(),
    must_be_emptied_since: z.string().datetime({ offset: true }).nullable(),
    created_at: z.string().datetime({ offset: true }),
    updated_at: z.string().datetime({ offset: true }),
});

const productionProductionMethodSchema = z.object({
    id: z.number(),
    display_name: z.string().nullable(),
    production_method_handle: z.string(),
});

const sortingRackSchema = z.object({
    id: z.number(),
    name: z.string(),
    production_id: z.number(),
    production_production_method: productionProductionMethodSchema,
    sorting_bins: z.array(sortingBinSchema),
    first_order_at: z.string().datetime({ offset: true }).nullable(),
    created_at: z.string().datetime({ offset: true }),
    updated_at: z.string().datetime({ offset: true }),
});

const listRacks = authedEndpoint.query(
    ({ search_params, production_method_id }) => ({
        url: `production-methods/${production_method_id}/sorting-racks`,
        searchParams: search_params,
    }),
    {
        input: z.object({
            search_params: z
                .object({
                    cursor: z.string(),
                    filter: z
                        .object({
                            q: z.string(),
                        })
                        .partial(),
                    per_page: z.number(),
                })
                .partial()
                .optional(),
            production_method_id: z.number(),
        }),
        output: z.object({
            data: z.array(sortingRackSchema),
            meta: z.object({
                path: z.string(),
                per_page: z.number(),
                next_cursor: z.string().nullable(),
                prev_cursor: z.string().nullable(),
            }),
            links: z.object({
                first: z.string().nullable(),
                last: z.string().nullable(),
                prev: z.string().nullable(),
                next: z.string().nullable(),
            }),
        }),
    },
);

const getRack = authedEndpoint.query(
    ({ production_method_id, sorting_rack_id }) => ({
        url: `production-methods/${production_method_id}/sorting-racks/${sorting_rack_id}`,
    }),
    {
        input: z.object({
            production_method_id: z.number(),
            sorting_rack_id: z.number(),
        }),
        output: sortingRackSchema,
    },
);

const createRack = authedEndpoint.mutation(
    ({ production_method_id, rack }) => ({
        url: `production-methods/${production_method_id}/sorting-racks`,
        method: "POST",
        json: rack,
    }),
    {
        input: z.object({
            production_method_id: z.number(),
            rack: z.object({
                name: z.string(),
                number_of_bins: z.number(),
            }),
        }),
        output: sortingRackSchema,
    },
);

const updateRack = authedEndpoint.mutation(
    ({ production_method_id, sorting_rack_id, rack }) => ({
        url: `production-methods/${production_method_id}/sorting-racks/${sorting_rack_id}`,
        method: "PATCH",
        json: rack,
    }),
    {
        input: z.object({
            production_method_id: z.number(),
            sorting_rack_id: z.number(),
            rack: sortingRackSchema,
        }),
        output: sortingRackSchema,
    },
);

const deleteRack = authedEndpoint.mutation(
    ({ production_method_id, sorting_rack_id }) => ({
        url: `production-methods/${production_method_id}/sorting-racks/${sorting_rack_id}`,
        method: "DELETE",
    }),
    {
        input: z.object({
            production_method_id: z.number(),
            sorting_rack_id: z.number(),
        }),
    },
);

import { sortingRackBins } from "./sortingRackBins";

export const sortingRacks = router({
    list: listRacks,
    get: getRack,
    create: createRack,
    update: updateRack,
    delete: deleteRack,

    // Sub-Endpoints
    sortingRackBins,
});
