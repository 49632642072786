import type { RouteRecordRaw } from "vue-router";

function getSlug(
    routes: readonly RouteRecordRaw[],
    pathSplit: string[],
): string | undefined {
    const [slug] = pathSplit;

    if (slug === undefined || isInvalidSlug(routes, slug)) {
        return undefined;
    }

    return slug;
}

function isInvalidSlug(
    routes: readonly RouteRecordRaw[],
    slug: string,
): boolean {
    const pages = routes.filter((route) => {
        const name = route.name as string;

        return name.includes(slug);
    });

    return pages.length > 0;
}

export function useSlug() {
    const route = useRoute();

    const {
        options: { routes },
    } = useRouter();

    return computed(() => {
        const pathSplit = route.fullPath
            .split("/")
            .filter((path) => path !== "");

        return getSlug(routes, pathSplit);
    });
}
